import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import Form from "react-bootstrap/Form";
import GradeTableItem from "./GradeTableItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { fetchTeacherGroups } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { createHeaders } from "../../functions/apiService";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGroup } from "../../store/action";
import { OverlayTrigger, Popover } from "react-bootstrap";


function GradeTable({ role, onGroupChange }) {
	const { groupId: paramGroupId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const selectedGroup = useSelector((state) => state.selectedGroup);

	const [getData, setGetData] = useState(null);
	const [allStudents, setAllStudents] = useState(null);

	const daysOfWeek = ["B.e.", "Ç.a.", "Ç.", "C.a.", "C.", "Ş.", "B."];
	const [openSelectOne, setOpenSelectOne] = useState(false);
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [openSelectSubject, setOpenSelectSubject] = useState(false);
	const [openSelectGroup, setOpenSelectGroup] = useState(false);
	const [currentWeek, setCurrentWeek] = useState(
		getCurrentWeekRange(new Date())
	);
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const calendarRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState([]);
	const [selectedType, setSelectedType] = useState("daily");
	const [sortBy, setSortBy] = useState("sort[students.full_name]");
	const [sortDirection, setSortDirection] = useState("asc");

	useEffect(() => {
		// Retrieve group_id from location state
		const state = location.state;
		if (state && state.group_id) {
			dispatch(setSelectedGroup(state.group_id));
		}
	}, [dispatch, location.state]);



	const queryParams = new URLSearchParams(location.search);

	// Səhifə və səhifələmə dəyərlərini URL-dən oxumaq
	const initialPage = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
	const initialPerPage = queryParams.get('pagination') ? parseInt(queryParams.get('pagination')) : 10;

	const [currentPage, setCurrentPage] = useState(initialPage);
	const [perPage, setPerPage] = useState(initialPerPage);
	const [totalPages, setTotalPages] = useState(0); // ümumi səhifə sayını öz ehtiyacınıza uyğun düzəldin

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const handlePerPageChange = (event) => {
		setPerPage(Number(event.target.value));
		setCurrentPage(1); // PerPage dəyişdikdə səhifəni 1-ə sıfırlayırıq
	};

	// Səhifə və pagination dəyərlərini URL-ə yazmaq üçün useEffect
	useEffect(() => {
		const params = new URLSearchParams();
		params.set("page", currentPage);
		params.set("pagination", perPage);
		navigate({ search: params.toString() });
	}, [currentPage, perPage, navigate]);



	const handlePrevWeek = () => {
		const prevWeekStart = parseDate(currentWeek.start);
		prevWeekStart.setDate(prevWeekStart.getDate() - 7);
		setCurrentWeek(getCurrentWeekRange(prevWeekStart));
	};

	const handleNextWeek = () => {
		const nextWeekStart = parseDate(currentWeek.start);
		nextWeekStart.setDate(nextWeekStart.getDate() + 7);
		setCurrentWeek(getCurrentWeekRange(nextWeekStart));
	};

	function getCurrentWeekRange(date) {
		const today = new Date(date);
		const dayOfWeek = today.getDay();

		const startOfWeek = new Date(today);
		startOfWeek.setDate(
			today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
		);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		return {
			start: formatDate(startOfWeek),
			end: formatDate(endOfWeek),
		};
	}

	function formatDate(date) {
		return date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	}

	function parseDate(dateString) {
		const [day, month, year] = dateString.split(".");
		return new Date(`${year}-${month}-${day}`);
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setCurrentWeek(getCurrentWeekRange(date));
		setShowCalendar(false);
	};

	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (groups && groups.length > 0) {
			if (!selectedGroup) {
				dispatch(setSelectedGroup(groups[0].id));
			}
		}
	}, [groups, selectedGroup, dispatch]);

	const handleGroupChange = (e) => {
		const groupId = e.target.value;
		dispatch(setSelectedGroup(groupId));
	};

	const handleTypeChange = (e) => {
		setSelectedType(e.target.value);
	};

	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchTeacherGroups(navigate);
				const sortedData = data.sort((a, b) => {
					const nameA = a.name.toLowerCase(); // Convert to lowercase
					const nameB = b.name.toLowerCase(); // Convert to lowercase
					return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
				});
				setGroups(sortedData);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	const getFormattedDate = () => {
		const dateToFormat = selectedDate;

		return dateToFormat.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	};

	useEffect(() => {
		if (selectedGroup && selectedType) {
			const formattedDate = getFormattedDate();

			const dateToSend = currentWeek.start || formattedDate;

			const headers = createHeaders();

			const requestData = {
				group_id: selectedGroup,
				date: dateToSend,
				status: selectedType,
			};
			const sortQuery = sortBy ? `&${sortBy}=${sortDirection}` : "";

			fetch(
				`${consts.API_URL}/teacher/group/get/student?pagination=${perPage}&page=${currentPage}&${sortQuery}`,
				{
					method: "POST",
					headers: headers,
					body: JSON.stringify(requestData),
				}
			)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setGetData(data);
					setTotalPages(data.pagination.total_pages);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});

			fetch(`${consts.API_URL}/teacher/group/get/student?pagination=1000`, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(requestData),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setAllStudents(data);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});
		}
	}, [
		selectedGroup,
		selectedType,
		selectedDate,
		currentWeek.start,
		perPage,
		currentPage,
		sortBy,
		sortDirection,
	]);

	const handleNavigateToExercises = () => {
		navigate(`/add-grade/${selectedGroup}`, {
			state: {
				getData: allStudents,
				selectedGroup: selectedGroup,
				groups: groups,
			},
		});
	};

	const renderSchedule = (groupId) => {

		const selectedGroupData = groups.find((group) => group.id === Number(groupId));

		if (!selectedGroupData || !selectedGroupData.schedules || selectedGroupData.schedules.length === 0) {
			return <p>Cədvəl mövcud deyil</p>;
		}

		return (
			<table className="turn-wrapper">
				<tbody>
					{selectedGroupData.schedules.map((schedule, index) => (
						<tr key={index}>
							<td>
								<span className="turn-left">{schedule.week_day}</span>
							</td>
							<td>
								<span className="turn-right">
									<div>
										{schedule.time}
										{schedule.end_time && ` - ${schedule.end_time}`}
									</div>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};


	return (
		<>
			<div className="groups">
				<div className="groups-header groups-header-grade">
					<div className="header-left">
						<div className="group-subject group-subject-selection d-flex align-items-center">
							<p className="group-subject-name">Qrup: </p>
							<Form.Select
								className={`selection ${openSelectGroup ? "active" : ""}`}
								onClick={() => setOpenSelectGroup((prev) => !prev)}
								onChange={handleGroupChange}
								value={selectedGroup || ""}
							>
								{groups.map((group) => (
									<option key={group.id} value={group.id}>
										{group.name}
									</option>
								))}
							</Form.Select>
							<div className="group-schedules">
								<OverlayTrigger
									placement="right"
									rootClose
									trigger="click"
									overlay={
										<Popover id="popover-positioned-right">
											<Popover.Body style={{ padding: "5px" }}>
												{renderSchedule(selectedGroup)}
											</Popover.Body>
										</Popover>
									}
								>
									<span className="d-inline-block">
										<button className="operation-btn gray-btn text-white">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												color="#fff"
												fill="none"
											>
												<circle
													cx="12"
													cy="12"
													r="10"
													stroke="currentColor"
													strokeWidth="1.5"
												/>
												<path
													d="M9.5 9.5L12.9999 12.9996M16 8L11 13"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									</span>
								</OverlayTrigger>
							</div>
						</div>
					</div>
					<div className="header-right">
						<div className="group-subject d-flex align-items-center">
							<p className="group-subject-name">Qiymət tipi: </p>
							<Form.Select
								className={`selection ${openSelectSubject ? "active" : ""}`}
								onClick={() => setOpenSelectSubject((prev) => !prev)}
								onChange={handleTypeChange}
							>
								<option value="daily">Günlük</option>
								<option value="exam">İmtahan</option>
								<option value="quiz">Quiz</option>
								<option value="project">Disiplin</option>
							</Form.Select>
						</div>
						<div className="week-navigation">
							<button onClick={handlePrevWeek}>
								<svg
									width="7"
									height="12"
									viewBox="0 0 7 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M1.8938 6.11523L6.04779 1.93164C6.35407 1.62207 6.35407 1.12402 6.04779 0.814453C5.90137 0.666016 5.70148 0.582031 5.4928 0.582031C5.28412 0.582031 5.08423 0.666016 4.93781 0.814453L0.227783 5.55664C-0.0759277 5.86816 -0.0759277 6.36426 0.227783 6.67578L4.93781 11.418C5.08447 11.5654 5.2843 11.6494 5.4928 11.6494C5.70129 11.6494 5.90112 11.5654 6.04779 11.418C6.3515 11.1064 6.3515 10.6104 6.04779 10.2998L1.8938 6.11523Z"
										fill="#707070"
									/>
								</svg>
							</button>
							<button className="week-current" onClick={handleToggleCalendar}>
								{currentWeek.start} - {currentWeek.end}
							</button>
							<button onClick={handleNextWeek}>
								<svg
									width="7"
									height="12"
									viewBox="0 0 7 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.38379 6.11523L0.229736 1.93164C-0.0765381 1.62207 -0.0765381 1.12402 0.229736 0.814453C0.376221 0.666016 0.576172 0.582031 0.78479 0.582031C0.993408 0.582031 1.19336 0.666016 1.33972 0.814453L6.04968 5.55664C6.35339 5.86816 6.35339 6.36426 6.04968 6.6748L1.33972 11.418C1.19312 11.5654 0.993286 11.6494 0.78479 11.6494C0.576294 11.6494 0.376465 11.5654 0.229858 11.418C-0.0737305 11.1064 -0.0738526 10.6104 0.229858 10.2998L4.38379 6.11523Z"
										fill="#707070"
									/>
								</svg>
							</button>
						</div>
						<Form.Select
							className={`selection ${openSelectOne ? "active" : ""}`}
							onClick={() => setOpenSelectOne((prev) => !prev)}
							onChange={handleSortChange}
							value={`${sortBy}${sortDirection ? `=${sortDirection}` : ""}`}
						>
							<option value="">Sırala</option>
							<option value="sort[students.full_name]=asc">Tələbə (A-Z)</option>
							<option value="sort[students.full_name]=desc">
								Tələbə (Z-A)
							</option>
							<option value="week_avg=asc">Həftəlik Ortalama (Artan)</option>
							<option value="week_avg=desc">Həftəlik Ortalama (Azalan)</option>
							<option value="general_avg=asc">Ümümi Ortalama (Artan)</option>
							<option value="general_avg=desc">Ümümi Ortalama (Azalan)</option>
						</Form.Select>
					</div>
				</div>
				<div className="groups-body">
					<table className="grade-table">
						<thead>
							<tr>
								<th>
									<div
										className="left column-content"
										style={{ width: "230px", paddingLeft: "20px" }}
									>
										Tələbə
									</div>
								</th>
								<th>
									<div className="column-content" style={{ width: "100px" }}>
										Email
									</div>
								</th>
								<th>
									<div className="column-content" style={{ width: "100px" }}>
										Telefon
									</div>
								</th>
								{daysOfWeek.map((day) => (
									<th key={day}>{day}</th>
								))}
								<th>
									<div className="column-content">Ü.O.</div>
								</th>
								<th>
									<div className="right column-content">H.O.</div>
								</th>
							</tr>
						</thead>

						<tbody>
							{getData &&
								getData.data &&
								getData.data.students &&
								getData.data.students.map((student, i) => (
									<GradeTableItem
										data={student}
										key={i}
										grades={student.grades}
									/>
								))}
						</tbody>
					</table>
				</div>
				<div className="groups-footer justify-content-between">
					<div className="groups-average d-flex justify-content-between">
						{getData && getData.data && getData.data.averages && (
							<>
								<p className="group-overall-average">
									Qrup ortalaması (ümumi):{" "}
									<span>{getData.data.averages.overall_general}</span>
								</p>
								<p className="group-weekly-average">
									Qrup ortalaması (həftəlik):{" "}
									<span>{getData.data.averages.general_week}</span>
								</p>
							</>
						)}
					</div>
					<div className="groups-pagination d-flex">
						<Pagination
							count={totalPages}
							page={currentPage}
							onChange={handlePageChange}
							className="pagination"
							variant="outlined"
							shape="rounded"
						/>

						<Form.Select
							className={`selection ${openSelectTwo ? "active" : ""}`}
							onClick={() => setOpenSelectTwo((prev) => !prev)}
							onChange={handlePerPageChange}
						>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</Form.Select>
					</div>
				</div>
				{showCalendar && (
					<div className="calendar-popup">
						<DatePicker
							selected={selectedDate}
							onChange={handleDateChange}
							inline
						/>
					</div>
				)}
			</div>
			<div className="grade-btns mb-5">
				<Link to="/exercises" className="btn-grey button">
					Tapşırıq əlavə et
				</Link>
				<button className="btn-add button" onClick={handleNavigateToExercises}>
					<span>Qiymət yaz</span>
				</button>
			</div>
		</>
	);
}

export default GradeTable;
