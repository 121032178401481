// export const API_URL = "https://dp-api.coders.edu.az/api";
// export const COMPANY_NAME = "Performance";
// export const FAVICON_PATH = "/favicon.ico";
// export const LOGO_PATH_LOGIN = "../img/performance-logo_black.png";
// export const LOGO_PATH_NAVBAR = "../img/performance-logo_white.png";

let aboutInfo = {
	logo_white: "",
	logo_black: "",
	company_name: "",
	description: ""
};

export const setAboutInfo = (newInfo) => {
	aboutInfo = { ...aboutInfo, ...newInfo };
};

export const getAboutInfo = () => aboutInfo;


export const API_URL = "https://api-dp.coders.az/api";
export const API_URL_IMG = "https://api-dp.coders.az";
// export const COMPANY_NAME = "Bildungswerk der Baden";
// export const FAVICON_PATH = "../img/favicon.ico";
// export const LOGO_PATH_LOGIN = "../img/logo-white-dualvet.svg";
export const LOGO_PATH_NAVBAR = "../img/logo-black-dualvet.svg ";