import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import WeekPagination from "../components/teachers/WeekPagination";
import * as consts from "../consts/Consts";
import { handleUnauthorizedError } from "../functions/authUtils";
import { createHeaders, fetchAboutMe } from "../functions/apiService";
import { useDispatch } from 'react-redux';
import { setGroupName } from '../store/action';

const CourseSchedule = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [role, setRole] = useState("")

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutMe(navigate);
				setRole(data.role);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			}
		};

		fetchData();
	}, [navigate]);

	const weekDayName = [
		"Bazar ertəsi",
		"Çərşənbə axşamı",
		"Çərşənbə",
		"Cümə axşamı",
		"Cümə",
		"Şənbə",
		"Bazar",
	];

	const hoursList = [
		"07:00",
		"08:00",
		"09:00",
		"10:00",
		"11:00",
		"12:00",
		"13:00",
		"14:00",
		"15:00",
		"16:00",
		"17:00",
		"18:00",
		"19:00",
		"20:00",
		"21:00",
		"22:00",
		"23:00",
	];

	const defaultColors = [
		"#F1F1FA",
		"#FFDFFD",
		"#FFD3B4",
		"#CDF0D5",
		"#F9F19D",
	];

	const getRandomColor = () => {
		const randomIndex = Math.floor(Math.random() * defaultColors.length);
		return defaultColors[randomIndex];
	};


	const getCurrentDay = () => {
		const day = new Date().getDay();
		return day === 0 ? 7 : day;
	};

	const [activeDay, setActiveDay] = useState(getCurrentDay());
	const [scheduleData, setScheduleData] = useState([]);

	const getDateByActiveDay = (activeDay) => {
		const current = new Date();
		const difference = activeDay - getCurrentDay();
		current.setDate(current.getDate() + difference);
		return current;
	};

	const currentTime = (activeDay) => {
		const date = getDateByActiveDay(activeDay);
		const weekDay = activeDay;
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const dateTime = date.getDate().toString().padStart(2, "0");
		const year = date.getFullYear();

		return `${weekDayName[weekDay - 1]} —  ${dateTime}.${month}.${year}`;
	};

	const start = "10:12";
	const end = "12:00";

	function timeToSeconds(time) {
		if (!time) return 0;
		const [hours, minutes] = time.split(":").map(Number);
		return hours * 3600 + minutes * 60;
	}

	const startInSeconds = timeToSeconds(start);
	const endInSeconds = timeToSeconds(end);

	const [width, setWidth] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		setWidth(ref.current.scrollWidth);
	}, [width]);

	useEffect(() => {
		const timeDifferenceInSeconds = endInSeconds - startInSeconds;
		const hours = Math.floor(timeDifferenceInSeconds / 3600);
		const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);

		setHours(hours);
		setMinutes(minutes);
	}, [hours, minutes]);

	const groupScheduleByColumns = (schedule) => {
		const columns = [];
		schedule.forEach((item) => {
			let placed = false;
			for (let i = 0; i < columns.length; i++) {
				const lastItemInColumn = columns[i][columns[i].length - 1];
				if (timeToSeconds(item.time) >= timeToSeconds(lastItemInColumn.end_time)) {
					columns[i].push(item);
					placed = true;
					break;
				}
			}
			if (!placed) {
				columns.push([item]);
			}
		});
		return columns;
	};

	const fetchSchedule = async (day) => {
		if (!role) return;

		const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
		const dayParam = weekDays[day - 1];
		const apiURL = role === 'admin'
			? `${consts.API_URL}/admin/group/get/schedule?week=${dayParam}`
			: `${consts.API_URL}/teacher/group/get/schedule?week=${dayParam}`;

		try {
			const response = await fetch(apiURL, {
				method: "GET",
				headers: createHeaders(),
			});

			if (!response.ok) {
				if (response.status === 401) {
					handleUnauthorizedError(response, navigate);
				} else {
					throw new Error(`Error: ${response.statusText}`);
				}
			}

			let data = await response.json();
			if (!data || !data.data) {
				setScheduleData([]);
				console.warn(`No schedule data for ${dayParam}`);
				return;
			}

			const filteredData = data.data.filter(item => {
				const startTimeInSeconds = timeToSeconds(item.time);
				const endTimeInSeconds = timeToSeconds(item.end_time);
				const minTime = timeToSeconds("07:00");
				const maxTime = timeToSeconds("23:00");

				return startTimeInSeconds >= minTime && endTimeInSeconds <= maxTime;
			});

			setScheduleData(filteredData);
		} catch (error) {
			console.error(`Failed to fetch schedule for ${dayParam}`, error);
			setScheduleData([]);
		}
	};


	useEffect(() => {
		fetchSchedule(activeDay);
	}, [activeDay, role]);

	const calculateHeight = (startTime, endTime) => {
		const startInSeconds = timeToSeconds(startTime);
		const endInSeconds = timeToSeconds(endTime);
		const timeDifferenceInSeconds = endInSeconds - startInSeconds;

		const height = (timeDifferenceInSeconds / 3600) * 62;
		return height;
	};

	useLayoutEffect(() => {
		if (ref.current) {
			const computedWidth = ref.current.scrollWidth;
			setWidth(computedWidth);
		}
	}, [scheduleData]);

	useEffect(() => {
		if (role) {
			fetchSchedule(activeDay);
		}
	}, [activeDay, role]);

	// useEffect(() => {
	// 	const updateScroll = () => {
	// 		const tableElement = ref.current;
	// 		const cardElements = tableElement?.getElementsByClassName('card-content-wrap') || [];

	// 		if (cardElements.length > 3) {
	// 			tableElement.style.overflowX = 'auto';
	// 		} else {
	// 			tableElement.style.overflowX = 'hidden';
	// 		}
	// 	};
	// 	updateScroll();
	// }, [scheduleData]);


	// useEffect(() => {
	// 	const updateScroll = () => {
	// 		const tableElement = ref.current;
	// 		const cardElements = tableElement?.getElementsByClassName('card-content-wrap') || [];

	// 		if (tableElement.scrollWidth > tableElement.clientWidth) {
	// 			tableElement.style.overflowX = 'auto';
	// 		} else {
	// 			tableElement.style.overflowX = 'hidden';
	// 		}
	// 	};
	// 	updateScroll();
	// }, [scheduleData, width]);

	const recalculateTableWidth = () => {
		const tableElement = ref.current;
		if (tableElement) {
			const computedWidth = tableElement.scrollWidth;
			console.log(`computedWidth ${computedWidth}`)
			setWidth(computedWidth);
		}
	};

	useEffect(() => {
		recalculateTableWidth();
	}, [activeDay]);

	useEffect(() => {
		if (ref.current) {
			ref.current.scrollLeft = 0;
		}
	}, [activeDay]);

	useEffect(() => {
		const tableElement = ref.current;
		const cardElements = tableElement?.getElementsByClassName('card-content-wrap') || [];

		if (tableElement) {
			const computedWidth = tableElement.scrollWidth;
			setWidth(computedWidth);

			if (computedWidth > tableElement.clientWidth && cardElements.length > 0) {
				tableElement.style.overflowX = 'auto';
			} else {
				tableElement.style.overflowX = 'hidden';
			}
		}
	}, [scheduleData, activeDay]);


	useEffect(() => {
		recalculateTableWidth();
	}, []);

	const handleGroupClick = (groupId) => {
		if (role === 'admin') {
			navigate(`/group-details/${encodeURIComponent(groupId)}`);
		}
		else {
			navigate("/grade", { state: { group_id: groupId } });
		}
	};

	return (
		<>
			<div className="course-schedule">
				<div className="cs_container">
					<div className="cs_heading">
						<div>
							<h3 className="cs_title">Dərs cədvəli</h3>
							<span className="cs_date">{currentTime(activeDay)}</span>
						</div>

						<WeekPagination active={activeDay} setActiveDay={setActiveDay} />
					</div>

					<div className="cs_table-container">
						<div className="cs_table" ref={ref} >
							{hoursList?.map((hourItem, hourIndex) => (
								<div className="cs_table-inner" key={hourIndex} style={{ width: `${width}px` }}>
									<div className="cs_table-time">
										<span>{hourItem}</span>
									</div>

									<div className="cs_table-card">
										<div className="cs_table-card-wrap">
											{groupScheduleByColumns(scheduleData)?.map((column, columnIndex) => (
												<div className="cs_column" key={columnIndex} style={{ display: 'inline-block', width: '411px' }}>
													{column?.map((scheduleItem) => (
														hourItem.slice(0, 2).includes(scheduleItem.time.slice(0, 2)) && (
															<div
																className="card-content-wrap"
																key={scheduleItem.group?.id ?? `no-group-${Math.random()}`}
																style={{
																	top: `${(((+scheduleItem.time.slice(3) / 62) * 100) / 100) * 62}px`,
																	height: `${calculateHeight(scheduleItem.time, scheduleItem.end_time)}px`,
																	backgroundColor: getRandomColor(),
																	cursor: 'pointer'
																}}
																onClick={() => handleGroupClick(scheduleItem.group?.id)}
															>
																<div className="cc_content cc_title">
																	<div className="cc_content-inner">
																		<span>Qrup: </span>
																		<span>{scheduleItem.group?.name || "Qrup adı yoxdur"}</span>
																	</div>
																	<span>|</span>
																	<div className="cc_content-inner">
																		<span>Müəllim: </span>
																		<span>{scheduleItem.teacher?.full_name || "Müəllim məlumatı yoxdur"}</span>
																	</div>
																</div>
																<div className="cc_content cc_content-container">
																	<div className="cc_content-inner">
																		<span>Saat: </span>
																		<span>
																			{scheduleItem.time} - {scheduleItem.end_time ? scheduleItem.end_time : "End not set"}
																		</span>
																	</div>
																	<span>|</span>
																	<div className="cc_content-inner">
																		<span>Dərs: </span>
																		<span>{scheduleItem.subject?.name || "Dərs adı yoxdur"}</span>
																	</div>
																</div>
															</div>
														)
													))}
												</div>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div >
		</>
	);
};

export default CourseSchedule;
