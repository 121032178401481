import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import { setSelectedGroup } from "../../store/action";
import { handleUnauthorizedError } from "../../functions/authUtils";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import TopIcon from '../TopIcon';

function AverageScore() {
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const [totalAverage, setTotalAverage] = useState(null)
	const [weeklyAverage, setWeeklyAverage] = useState(null)

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const headers = createHeaders();

				const response = await fetch(`${consts.API_URL}/student/average/total`, {
					method: 'POST',
					headers: {
						...headers,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						group_id: selectedGroup,
					}),
				});

				if (response.status === 401) {
					handleUnauthorizedError(response, navigate);
					return;
				}

				if (!response.ok) {
					throw new Error('Ошибка при отправке данных');
				}

				const result = await response.json();
				setTotalAverage(result.data.average);
				console.log('Успешно обновлено:', result);
			} catch (error) {
				console.error('Произошла ошибка:', error);
			}
		};

		if (selectedGroup) {
			fetchData();
		}
	}, [selectedGroup, navigate]);;

	console.log(totalAverage)

	return (
		<div className="averages">
			<Row>
				<Col md={6}>
					<div className="averages__item averages-total">
						<h4 className="averages__title">Ümumi ortalama</h4>
						<div className="averages__body">
							<div className="averages__score">
								<span className="score-total">{totalAverage}</span>
								<span className="score-100">/ 100</span>
							</div>
							{/* <p className="averages__absent">Ümumi  qayıb sayı: 14
								<span className="absent-count">14</span>
							</p> */}
						</div>
						<div className="averages__icon">
							<TopIcon formattedAvgTotal={totalAverage} />
						</div>
					</div>
				</Col>
				<Col md={6}>
					<div className="averages__item averages-weekly">
						<h4 className="averages__title">Həftəlik  ortalama</h4>
						<div className="averages__body">
							<div className="averages__score">
								<span className="score-total">92.5</span>
								<span className="score-100">/ 100</span>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default AverageScore
