import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as consts from "../consts/Consts";
import { fetchAboutUs } from "../functions/apiService";
import { handleUnauthorizedError } from "../functions/authUtils";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError(""); // Error mesajını sıfırlayın

    if (!email) {
      setError("Email tələb olunur");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Düzgün email daxil edin");
      return;
    }

    try {
      const response = await fetch(`${consts.API_URL}/auth/password/forgot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        if (response.status === 400) {
          setError("Emaili düzgün daxil edin");
        } else {
          setError("Error");
        }
        return;
      }

      navigate("/reset-password"); 
    } catch (error) {
      setError("Error", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAboutUs(navigate);
        consts.setAboutInfo({
          logo_black: data.logo_black,
        });
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <section className="login">
        <Container fluid>
          <Row>
            <Col md={6} className="login__left">
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <div className="login__logo mb-5">
                  <img
                    src={consts.getAboutInfo()?.logo_black}
                    alt=""
                    className={`${consts.getAboutInfo()?.company_name === "Performance" ? "" : "login-logo"}`}
                  />
                </div>
                <Form onSubmit={handleResetPassword} style={{ width: "300px" }}>
                  <p className="text-center login-left__title mb-5">
                    Xoş gördük! Məlumatlarınızı yazaraq daxil olun.
                  </p>

                  {error && <Alert variant="danger">{error}</Alert>}

                  <Form.Group controlId="formLogin">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="performans@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isInvalid={!!error} // Error varsa invalid vəziyyətini göstər
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100 login__btn"
                  >
                    Şifrəni sıfırla
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={6} className="login__right">
              <div className="login__background">
                <div className="h-100">
                  <div className="login__top">
                    <div className="img-container_login">
                      <div className="login__img login__img-back">
                        <img src="/img/iphone_2.png" alt="" />
                      </div>
                      <div className="login__img login__img-front">
                        <img src="/img/iphone_1.png" alt="" />
                      </div>
                    </div>

                    <div className="login__qr">
                      <div className="login-qr__img">
                        <img src="/img/qr.png" alt="" />
                      </div>
                      <p className="login-info">
                        QR kodu oxudaraq tətbiqi yükləyin
                      </p>
                    </div>
                  </div>
                  <div className="login__bottom">
                    <h1>Performance App</h1>
                    <p className="login__text">
                      iOS və ya Play Market vasistəsilə Coders Performans mobil
                      tətbiqini yükləyib təhsilinizlə bağlı bütün prosesləri
                      istədiyiniz yerdən izləyə bilərsiniz.
                    </p>
                    <span className="icon-btns"></span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgetPassword;
